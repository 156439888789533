import { login } from '@/api/login'
import { getToken, setToken, setUser, getUser, removeToken } from '@/utils/auth'
const user = {
    state: {
        token: getToken(),
        user: null
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
            setToken(token)
        },
        SET_USER(state, user) {
            state.user = user
        }
    },
    actions: {
        Login({ commit }, form) {
            return new Promise((resolve, reject) => {
                login(form).then((response) => {
                    const resp = response.result
                    commit('SET_TOKEN', JSON.stringify(response))
                    commit('SET_USER', response)
                    resolve(resp)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}
export default user