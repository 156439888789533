<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss">
// #app{
//   min-height: 100vh;
// }
</style>