/* 学生综合素质评价系统 */ 
// 内页添加meta.parentPath 以便进入内页 菜单还能选中它的首页
import Layout from '@/views/Layout.vue';
export default [
  {
    path: '/studentEvaluate',
    name: 'studentEvaluate',
    redirect: '/studentEvaluate/index',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'studentEvaluateIndex',
        component: () => import('@/views/studentEvaluate/index'),
      },
      {
        path: 'peopleList',
        name: 'studentEvaluatePeople',
        meta: {
          parentPath:'/studentEvaluate/index',   
        },
        component: () => import('@/views/studentEvaluate/peopleList'),
      },
      {
        path: 'detail',
        name: 'studentEvaluateDetail',
        meta: {
          parentPath:'/studentEvaluate/index',
        },
        component: () => import('@/views/studentEvaluate/detail'),
      },
      {
        path: 'realistic',
        name: 'studentEvaluateRealistic',
        component: () => import('@/views/studentEvaluate/realistic'),
      },
      {
        path: 'portrait',
        name: 'studentEvaluatePortrait',
        component: () => import('@/views/studentEvaluate/portrait'),
      },
      {
        path: 'total',
        name: 'studentEvaluateTotal',
        meta: {
          parentPath:'/studentEvaluate/portrait',
        },
        component: () => import('@/views/studentEvaluate/total'),
      },
      {
        path: 'totalDetail',
        name: 'studentEvaluateTotalDetail',
        meta: {
          parentPath:'/studentEvaluate/portrait',
        },
        component: () => import('@/views/studentEvaluate/totalDetail'),
      },

      {
        path: 'report',
        name: 'studentEvaluateReport',
        component: () => import('@/views/studentEvaluate/report'),
      },
      {
        path: 'reportDeatil',
        name: 'studentEvaluateReportDetail',
        meta: {
          parentPath:'/studentEvaluate/report',
        },
        component: () => import('@/views/studentEvaluate/reportDetail'),
      }
    ]
  }
];
