<template>
  <div class="base-header">
    <div class="container">
      <div class="header-logo">
        <a class="logo" href="/">{{title}}</a>
      </div>
      <div class="header-menu">
        <div class="img">
          <img src="@/assets/img/login/user.png" alt="" />
        </div>
        <div class="hello">{{ user.realname }}，</div>
        <div class="className">{{ className }}</div>
        <div @click="logout" class="logout">
          <img src="@/assets/img/login/log.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { wechat } from '@/api/user';
import { logout } from '@/api/login';

export default {
  name: 'MyHeader',
  data() {
    return {
      user: JSON.parse(localStorage.getItem('ss-userInfo')),
      className: localStorage.getItem('ss-className'),
      title:localStorage.getItem('menuName')
    };
  },
  watch: {
    user: {
      handler(val) {},
      deep: true,
    }
  },
  created() {
    setTimeout(() => {
      let mycode = this.$route.query.code;
      let params = {
        code: mycode,
      };
      if (mycode) {
        wechat(params).then(res => {
          if (res.success) {
            this.$message({
              message: res.message,
              type: 'success',
            });
            this.$router.push(this.$route.path);
          } else {
            this.$message({
              message: res.message,
              type: 'warning',
            });
          }
        });
      } else {
      }
    }, 100);
  },
  methods: {
    logout() {
      this.$confirm('确认退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'messageBox',
      }).then(() => {
        logout().then(res => {
          if (res.success) {
            if (localStorage.getItem('ss-loginName') === '/login' || localStorage.getItem('ss-loginName') === 'wlogin') {
              if(localStorage.getItem('disabledSchool')){
                this.$router.push(`/login?school=${localStorage.getItem('disabledSchool')}`);
              }else{
                this.$router.push('/login');
              }
            }else if(localStorage.getItem('ss-loginName') === '/szhzx'){
              this.$router.push('/szhzx');
            }else{
              this.$router.push('/login');
            }
            localStorage.removeItem('ss-token');
            localStorage.removeItem('ss-userInfo');
            localStorage.removeItem('ss-className');
            localStorage.removeItem('paperId');
            localStorage.removeItem("disabledSchool");
            localStorage.removeItem("ss-loginName");
          } else {
            this.$message({
              message: res.message,
              type: 'warning',
            });
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/mixin.scss';
.base-header {
  width: 100%;
  height: 80px;
  background: url('../assets/img/homepage/head.png') no-repeat center;
  background-size: 100%;
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 18px;
  .container {
    width: 1328px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-logo {
      a {
        color: #fff;
        font-size: 36px;
        font-family: FZZhengHeiS-DB-GB;
        font-weight: 400;
        letter-spacing: 3px;
        margin-left: 51px
      }
    }
    .header-menu {
      margin-right: 51px;
      display: flex;
      align-items: center;
      .img {
        width: 28px;
        height: 28px;
        margin-right: 14px;
      }
      .logout {
        margin-left: 29px;
        width: 101px;
        height: 36px;
        cursor: pointer;
      }
      // margin-top: 80px;
      // .user {
      //   display: flex;
      //   align-items: center;
      //   font-weight: bold;
      //   .img{
      //     width: 64px;
      //     height: 64px;
      //     margin-right: 20px;
      //     img{
      //       width: 100%;
      //       height: 100%;
      //     }
      //   }
      //   img {
      //     width: 15px;
      //     height: 15px;
      //     margin-right: 5px;
      //   }
      //   .hello {
      //     font-style: italic;
      //     font-size: 19px;
      //     color: #fff;
      //     margin-bottom: 10px;
      //   }
      //   .userinfo {
      //     cursor: pointer;
      //   }
      //   .el-button {
      //     background-color: #ff8400;
      //     border: none;
      //     color: #ffffff;
      //     margin: 6px 0;
      //   }
      // }
    }
    /deep/.el-message-box {
      &__title {
        font-size: 20px !important;
      }
    }
  }
}
</style>
