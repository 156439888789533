import { request } from '@/utils/request';
/**
 * @name: get 请求
 * @param { string } url 接口地址
 * @return {object} params 传递参数
 */
export function get(url, params = {}) {
  return request({
    url: url,
    method: 'get',
    params: params,
  });
}

/**
 * @name: post 请求
 * @param { string } url 接口地址
 * @return {object} data 传递参数
 */
export function post(url, data = {}) {
  return request({
    url: url,
    method: 'post',
    data: data,
  });
}

/**
 * @name: del 请求
 * @param { string } url 接口地址
 * @return {object} params 传递参数
 */
export function del(url, params = {}) {
  return request({
    url: url,
    method: 'delete',
    params: params,
  });
}

/**
 * @name: put 请求
 * @param { string } url 接口地址
 * @return {object} data 传递参数
 */
export function put(url, data = {}) {
  return request({
    url: url,
    method: 'put',
    data: data,
  });
}

export default { post, get, del, put };
