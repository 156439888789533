<template>
  <div class="nav-menu1">
    <el-menu
      text-color="#636a76"
      router
      @select="select"
      :default-active="activePath"
    >
      <el-menu-item class="menu-wrap" :index="item.path" v-for="(item, i) in menu" :key="i">
        <div class="item" slot="title">
          <div class="icon">
            <img :src="item.path == ( $route.meta.parentPath || $route.path)?item.backgroundOn:item.background " alt />
          </div>
          <p>{{ item.name }}</p>
        </div>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { teacherEvaluateMenu, questionMenu, studentEvaluateMenu } from '@/router/meau';
export default {
  data() {
    return {
      menu: '',
      path: '',
      curPath: null,
    };
  },
  computed: {
    activePath() {
     // console.log(this.$route,'===')
      if(this.$route.meta.parentPath){
        return this.$route.meta.parentPath
      }else{
        return this.$route.path
      }
    },
  },
  created() {
    let url = this.$route.path
    let index = url.indexOf('/',1)
    let path = "";
    if(index == -1){
      path = url.substr(0)
    }else{
      path = url.substr(0,index)
    }
    switch (path) {
      case '/teacher':
        this.menu = teacherEvaluateMenu;
        break;
      // case '/Survey':
      //   this.menu = meau;
      //   break;
      // 问卷调查
      case '/MyQuestion':
        this.menu = questionMenu;
        break;
      //其他
      // case '/user':
      //   this.menu = meau2;
      //   break;
      // case '/student':
      //   this.menu = meau2;
      //   break;
      // case '/MyTask':
      //   this.menu = meau2;
      //   break;
      case '/studentEvaluate':
        this.menu = studentEvaluateMenu;
      default:
        break;
    }
  },
  methods: {
    select(path) {
      this.curPath = path;
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-menu1 {
  width: 98px;
  .el-menu {
    border-right: 0;
    .el-menu-item {
      height: auto;
      line-height: normal;
      padding: 20px 0 !important;
      &.is-active {
        background-color: #217bff;
        color: #fff;
      }
      .item {
        font-size: 16px;
        text-align: center;
        .icon {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
