/* 教学质量评价系统 */ 
// 内页添加meta.parentPath 以便进入内页 菜单还能选中它的首页
import Layout from '@/views/Layout.vue';
export default [
  {
    path: '/teacher',
    name: 'Teacher',
    redirect: '',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Teacher',
        component: () => import( '@/views/teacherEvaluate/index.vue'),
      },
      {
        path: 'list',
        name: 'TeacherList',
        meta: {
          parentPath:'/teacher',
        },
        component: () => import('@/views/teacherEvaluate/list.vue'),
      },
      {
        path: 'detail',
        name: 'TeacherDetail',
        meta: {
          parentPath:'/teacher',
        },
        component: () => import('@/views/teacherEvaluate/detail.vue'),
      }
    ]
  }
];
