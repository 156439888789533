/* 教师 */
let teacherEvaluateMenu = [
  { 
    id: 0, 
    name: '平台首页', 
    path: '/', 
    background: require('@/assets/img/base/homepage.png'), 
    backgroundOn: require('@/assets/img/base/homepage1.png') 
  },
  { 
    id: 1, 
    name: '评价教师', 
    path: '/teacher', 
    background: require('@/assets/img/base/evaluate.png'), 
    backgroundOn: require('@/assets/img/base/evaluate1.png') 
  },
  { 
    id: 6, 
    name: '个人中心', 
    path: '/usercenter', 
    background: require('@/assets/img/base/nav_techer.png'), 
    backgroundOn: require('../assets/img/base/nav_techer1.png') 
  },
];
/* 问卷 */
let questionMenu = [
  { 
    id: 0, 
    name: '平台首页', 
    path: '/', 
    background: require('@/assets/img/base/homepage.png'), 
    backgroundOn: require('@/assets/img/base/homepage1.png') 
  },
  { 
    id: 3, 
    name: '我的问卷', 
    path: '/MyQuestion', 
    background: require('@/assets/img/base/nav_myQues.png'), 
    backgroundOn: require('@/assets/img/base/nav_myQues1.png') 
  },
  { 
    id: 6, 
    name: '个人中心', 
    path: '/usercenter', 
    background: require('@/assets/img/base/nav_techer.png'), 
    backgroundOn: require('@/assets/img/base/nav_techer1.png') 
  }
];
// let meau2 = [
//   { id: 0, name: '平台首页', path: '/', background: require('@/assets/img/base/homepage.png'), backgroundOn: require('@/assets/img/base/homepage1.png') },
//   { id: 2, name: '评价学生', path: '/student', background: require('@/assets/img/base/nav_student.png'), backgroundOn: require('@/assets/img/base/nav_student.png') },
//   { id: 3, name: '我的评价', path: '/user', background: require('@/assets/img/base/nav_myCom.png'), backgroundOn: require('@/assets/img/base/nav_myCom.png') },
//   { id: 5, name: '我的任务', path: '/MyTask', background: require('@/assets/img/base/nav_myTask.png'), backgroundOn: require('@/assets/img/base/nav_myTask.png') },
//   { id: 5, name: '评价统计', path: '/Survey', background: require('@/assets/img/base/nav_statis.png'), backgroundOn: require('../assets/img/base/nav_statis1.png') },
//   { id: 6, name: '个人中心', path: '/usercenter', background: require('@/assets/img/base/nav_techer.png'), backgroundOn: require('@/assets/img/base/nav_techer1.png') },
// ];

/* 学员 */
let studentEvaluateMenu = [
  { 
    id: 0, 
    name: '平台首页', 
    path: '/', 
    background: require('@/assets/img/base/homepage.png'), 
    backgroundOn: require('@/assets/img/base/homepage1.png') 
  },
  { 
    id: 1, 
    name: '评价学生', 
    path: '/studentEvaluate/index', 
    background: require('@/assets/img/base/evaluate.png'), 
    backgroundOn: require('@/assets/img/base/evaluate1.png') 
  },
  { 
    id: 2, 
    name: '写实记录', 
    path: '/studentEvaluate/realistic', 
    background: require('@/assets/img/base/realistic.png'), 
    backgroundOn: require('../assets/img/base/realistic-active.png') 
  },
  { 
    id: 3, 
    name: '评价报告', 
    path: '/studentEvaluate/report', 
    background: require('@/assets/img/base/report.png'), 
    backgroundOn: require('../assets/img/base/report-active.png') 
  },
  { 
    id: 4, 
    name: '个人画像', 
    path: '/studentEvaluate/portrait', 
    background: require('@/assets/img/base/nav_statis.png'), 
    backgroundOn: require('../assets/img/base/nav_statis1.png') 
  },
  { 
    id: 5, name: '个人中心', 
    path: '/usercenter', 
    background: require('@/assets/img/base/nav_techer.png'), 
    backgroundOn: require('../assets/img/base/nav_techer1.png') 
  },
  // { 
  //   id: 6, 
  //   name: '评价统计', 
  //   path: '/studentEvaluate/total', 
  //   background: require('@/assets/img/base/nav_statis.png'), 
  //   backgroundOn: require('../assets/img/base/nav_statis1.png') 
  // },
];
export { teacherEvaluateMenu,questionMenu,studentEvaluateMenu };
