import {
  request
} from '@/utils/request'
export function bindWeChat() {
  return request({
    url: '/userCenter/bindWeChat/isStudentBindToWeChat',
    method: 'post'
  })
}
export function verifyUser(data) {
  return request({
    url: '/userCenter/bindWeChat/verifyUser',
    method: 'post',
    data
  })
}
export function wechat(data) {
  return request({
    url: '/userCenter/bindWeChat/isWeChatBindToUserOrStudent',
    method: 'post',
    data
  })
}
export function edit(data) {
  return request({
    url: '/base/student/edit',
    method: 'put',
    data
  })
}
export function changePassword(data) {
  return request({
    url: '/userCenter/changePassword',
    method: 'put',
    data
  })
}
export function queryById(data) {
  return request({
    url: '/base/student/queryById',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'get',
    params: data
  })
}
// 首页
export function stumenu(data) {
  return request({
    url: '/company/school/stumenu/list',
    method: 'get',
    params: data
  })
}