/* 问卷调查系统 */ 
// 内页添加meta.parentPath 以便进入内页 菜单还能选中它的首页
import Layout from '@/views/Layout.vue';
export default [{
  path: '/MyQuestion',
  name: 'MyQuestion',
  redirect: '',
  component: Layout,
  children: [
    {
      path: '',
      name: 'MyQuestion',
      component: () => import('@/views/myQuestion/index.vue'),
    },
    {
      path: 'qlist',
      name: 'qlist',
      meta: {
        parentPath:'/MyQuestion'
      },
      component: () => import('@/views/myQuestion/qlist.vue'),
    },
    {
      path: 'qdetail',
      name: 'qdetail',
      meta: {
        parentPath:'/MyQuestion'
      },
      component: () => import('@/views/myQuestion/qdetail.vue'),
    },
  ]
}]