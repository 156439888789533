<template>
  <div class="base-footer">
    <div>
      <span>
        <i class="iconfont icon-ditu"></i>
        地址：太原市小店区龙城大街102号盛锦国际B座6层
      </span>
      <span>电话：0351-5615309</span>
    </div>
    <div>
      <span>版权所有：山西华兴科软有限公司 晋ICP备05002232号-4</span>
      <span>建议使用360浏览器极速模式，分辨率1400*900以上访问本网站</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.base-footer {
  // position: absolute;
  // bottom: 0;
  // width: 100%;
  background-color: #0f4998;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  padding: 10px 0;
  margin-top: 20px;
  span{
    margin: 0 30px;
    line-height: 22px;
  }
}
</style>
