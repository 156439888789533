import router from './router' 
import {getUserInfo} from './api/login'
router.beforeEach((to,from,next)=>{
    const token=localStorage.getItem('ss-token')
    if(!token){
        if(to.path==='/login' || to.path==='/wlogin' || to.path==='/szhzx'){
            localStorage.setItem('ss-loginName', to.path)
            next()
        }else{
            next({path: '/login'})
        }
    }else{
        if(to.path==='/login' || to.path==='/szhzx'){
            next()
        }else{
            const userinfo=localStorage.getItem('ss-schoolId')
            if(userinfo){
                next()
            }else{
                // getUserInfo(token).then(response=>{
                //     const resp=response.data
                //     if(resp.flag){
                //         // 获取到, 保存数据
                //         localStorage.setItem('mgx-msm-user', JSON.stringify(resp.data)) 
                //         next()
                //     }else{
                //         next({path: '/login'})
                //     }
                // })
            }
        }
    }
})