import { request } from '@/utils/request';
export function getSchoolList() {
  return request({
    url: `/filter/school/list`,
    method: 'get',
  });
}
export function login(data) {
  return request({
    url: '/school/stuLogin',
    method: 'post',
    data,
  });
}
export function wechatLogin(data) {
  return request({
    url: '/school/wechatLogin',
    method: 'post',
    data,
  });
}
export function logout() {
  return request({
    url: '/sys/logout',
    method: 'put',
  });
}

export function getSchoolId(code) {
  return request({
    url: `/filter/schoolByCode/${code}`,
    method: 'get',
  });
}

// 退出系统 logout(localStorage.getItem(localStorage.getItem('mgx-msm-token'))).then(response => { // 清除本地数据 localStorage.removeItem('mgx-msm-token') localStorage.removeItem('mgx-msm-user') this.$router.push('/login') })
