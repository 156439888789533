import Vue from 'vue';
import VueRouter from 'vue-router';
// import Layout from '@/views/Layout.vue';
Vue.use(VueRouter);

import studentEvaluate from './studentEvaluate';
import teacherEvaluate from './teacherEvaluate';
import question from './question';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/login.vue'),
  },
  {
    path: '/szhzx',
    name: 'szhzx',
    component: () => import('@/views/Login/loginQuestion.vue'),
  },
  {
    path: '/',
    name: 'homepage',
    component: () => import('@/views/Homepage.vue'),
  },
  {
    path: '/usercenter',
    name: 'usercenter',
    component: () => import('../views/userCenter'),
  },
  ...studentEvaluate,
  ...teacherEvaluate,
  ...question,
];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: 'history',
  base: process.env.VUE_APP_NGINX_BASE_URL,
  routes,
});
export default router;
