<template>
  <div class="layout">
    <my-header ref="header"></my-header>
    <div class="container">
      <nav-menu :class="fixed ? 'fixed' : ''"></nav-menu>
      <router-view class="router-view-wrap"></router-view>
    </div>
    <base-footer></base-footer>
  </div>
</template>

<script>
import MyHeader from '@/components/MyHeader.vue';
import BaseFooter from '@/components/BaseFooter.vue';
import NavMenu from '@/components/NavMenu.vue';
import { queryById } from '@/api/user';

export default {
  components: {
    MyHeader,
    BaseFooter,
    NavMenu,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('ss-userInfo')),
      fixed: '',
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.$refs.header) {
        let clientHeight = this.$refs.header.$el.clientHeight;
        if (document.documentElement.scrollTop >= clientHeight) {
          
          this.fixed = true;
        } else {
          this.fixed = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 1328px;
  margin: 0 auto;
  box-sizing: border-box;
  &::after {
    content: '';
    display: block;
    clear: both;
  }
  .nav-menu1 {
    float: left;
    top: 0;
    transition: all 0.3s ease 0s;
  }
  .nav-menu1.fixed {
    position: fixed;
    top: 20px;
    transition: all 0.3s ease 0s;
  }
  .router-view-wrap {
    float: right;
    width: calc(100% - 120px);
    min-height: 800px;
  }
}
</style>
