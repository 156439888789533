import axios from 'axios';
import router from '@/router/index.js';
import { Message } from 'element-ui';
export function request(config) {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000,
  });
  instance.interceptors.request.use(
    config => {
      const token = localStorage.getItem('ss-token');
      const schoolId = localStorage.getItem('ss-schoolId');
      if (token) {
        config.headers['X-Access-Token'] = token;
        config.headers['tenant-id'] = schoolId;
      }
      return config;
    },
    err => {
      return Promise.reject(err);
    }
  );
  instance.interceptors.response.use(
    res => {
      //console.log(res, '=====request 响应 res');
      if (res.data.success) {
        return res.data;
      } else {
        console.log(res, '=====request 响应 res -->err');
        // 此接口success = false也放行 登陆错误三次后，出现划块验证码
        // 优化request() config 添加白名单
        if (res.config.url == '/school/stuLogin') {
          return res.data;
        } else {
          Message.warning(res.data.message);
          if (res.data.message.includes('Token无效')) {
            let code = localStorage.getItem('disabledSchool')
            if (code) {
              router.push(`/login?school=${code}`);
            } else {
              router.push('/login');
            }
            localStorage.removeItem('ss-token');
            localStorage.removeItem('ss-userInfo');
            localStorage.removeItem('ss-className');
            localStorage.removeItem('paperId');
            localStorage.removeItem('disabledSchool');
          }
          return Promise.reject(new Error(res.data.message));
        }
      }
    },
    err => {
      if (err.message.includes('timeout')) {
        Message({
          message: '网络超时',
          type: 'info',
        });
      }
      switch (err.response.status) {
        case 500:
          if (err.response.data.message.includes('Token失效')) {
            // localStorage.clear();
            localStorage.removeItem('ss-token');
            localStorage.removeItem('ss-userInfo');
            localStorage.removeItem('ss-className');
            localStorage.removeItem('paperId');
            localStorage.removeItem('disabledSchool');
            router.push(`/login`);
            Message({
              message: '登录已过期,请重新登录',
              type: 'info',
            });
          }
          break;
        case 504:
          Message({
            message: '网络超时',
            type: 'info',
          });
      }
    }
  );
  return instance(config);
}
