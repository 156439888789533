import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import 'lib-flexible'

Vue.config.productionTip = false;

import './assets/css/base.scss';
import './assets/iconfont/iconfont.css'

import * as filters from './filters' // global filters
// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// axios 请求封装
import { get, post, del, put } from '@/utils/service';
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$del = del;
Vue.prototype.$put = put;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

import wxlogin from 'vue-wxlogin';
Vue.component('wxlogin', wxlogin);

import moment from 'moment';
Vue.prototype.$moment = moment;

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

import './permission';

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
